/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /users/sign_out(.:format) */
export function destroyUserSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password/new(.:format) */
export function newUserPasswordPath(options?: object): string {
  return "/" + "users" + "/" + "password" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /public-api */
export function publicApiAppPath(options?: object): string {
  return "/" + "public-api" + $buildOptions(options, []);
}

/** /api/sites/internal(.:format) */
export function internalApiSitesPath(options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + "internal" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/sites/:id/business_hours(.:format) */
export function businessHoursApiSitePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + id + "/" + "business_hours" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/sites/:site_id/site_profiles(.:format) */
export function apiSiteSiteProfilesPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "site_profiles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/profile(.:format) */
export function apiSiteProfilePath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/advices(.:format) */
export function apiSiteAdvicesPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "advices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/advices/:id(.:format) */
export function apiSiteAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "advices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /api/sites/:site_id/site_user_roles(.:format) */
export function apiSiteSiteUserRolesPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "site_user_roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:id(.:format) */
export function apiSitePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/sites/:site_id/docks(.:format) */
export function apiSiteDocksPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "docks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/slots/:id/opened(.:format) */
export function openedApiSiteSlotPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "slots" + "/" + id + "/" + "opened" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /api/sites/:site_id/slots/blockers(.:format) */
export function blockersApiSiteSlotsPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "slots" + "/" + "blockers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/slots/availability(.:format) */
export function availabilityApiSiteSlotsPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "slots" + "/" + "availability" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/slots(.:format) */
export function apiSiteSlotsPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "slots" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/slots/:id(.:format) */
export function apiSiteSlotPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "slots" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /api/sites/:site_id/notifications/groups(.:format) */
export function groupsApiSiteNotificationsPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "notifications" + "/" + "groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/notifications/groups/:tab(.:format) */
export function tabApiSiteNotificationsPath(site_id: ScalarType, tab: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "notifications" + "/" + "groups" + "/" + tab + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","tab","format"]);
}

/** /api/sites/:site_id/notifications/groups/:tab/count(.:format) */
export function tabCountApiSiteNotificationsPath(site_id: ScalarType, tab: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "notifications" + "/" + "groups" + "/" + tab + "/" + "count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","tab","format"]);
}

/** /api/sites/:site_id/notifications/groups/:tab/mark_all_as_read(.:format) */
export function markAllAsReadApiSiteNotificationsPath(site_id: ScalarType, tab: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "notifications" + "/" + "groups" + "/" + tab + "/" + "mark_all_as_read" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","tab","format"]);
}

/** /api/sites/:site_id/notifications/global_count(.:format) */
export function globalCountApiSiteNotificationsPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "notifications" + "/" + "global_count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/notifications/:id/mark_as_read(.:format) */
export function markAsReadApiSiteNotificationPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "notifications" + "/" + id + "/" + "mark_as_read" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /api/sites/:site_id/notifications/:id/mark_as_unread(.:format) */
export function markAsUnreadApiSiteNotificationPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "notifications" + "/" + id + "/" + "mark_as_unread" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /api/sites/:site_id/sidebar_default(.:format) */
export function apiSiteSidebarDefaultPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "sidebar_default" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/orders/:order_id/changelog(.:format) */
export function apiSiteOrderChangelogPath(site_id: ScalarType, order_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "orders" + "/" + order_id + "/" + "changelog" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","order_id","format"]);
}

/** /api/sites/:site_id/orders(.:format) */
export function apiSiteOrdersPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "orders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/trade_item_categories(.:format) */
export function apiSiteTradeItemCategoriesPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "trade_item_categories" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites/:site_id/trade_item_categories/:id(.:format) */
export function apiSiteTradeItemCategoryPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "trade_item_categories" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /api/sites/:site_id/capacity/loading_unit_weight_setting(.:format) */
export function apiSiteCapacityLoadingUnitWeightSettingPath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "capacity" + "/" + "loading_unit_weight_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/sites(.:format) */
export function apiSitesPath(options?: object): string {
  return "/" + "api" + "/" + "sites" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/sites/new(.:format) */
export function newApiSitePath(options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/sites/:id/edit(.:format) */
export function editApiSitePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "sites" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/company_search(.:format) */
export function apiCompanySearchIndexPath(options?: object): string {
  return "/" + "api" + "/" + "company_search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/users/me(.:format) */
export function meApiUsersPath(options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + "me" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/users/login(.:format) */
export function loginApiUsersPath(options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + "login" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/users/:user_id/notification_setting(.:format) */
export function apiUserNotificationSettingPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "users" + "/" + user_id + "/" + "notification_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id","format"]);
}

/** /api/contributor/verify_email(.:format) */
export function apiContributorVerifyEmailPath(options?: object): string {
  return "/" + "api" + "/" + "contributor" + "/" + "verify_email" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/check_in_terminal/documents/:document_locale(.:format) */
export function apiCheckInTerminalDocumentPath(document_locale: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "check_in_terminal" + "/" + "documents" + "/" + document_locale + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["document_locale","format"]);
}

/** /api/check_in_terminal/advices/match(.:format) */
export function matchApiCheckInTerminalAdvicesPath(options?: object): string {
  return "/" + "api" + "/" + "check_in_terminal" + "/" + "advices" + "/" + "match" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/check_in_terminal/advices(.:format) */
export function apiCheckInTerminalAdvicesPath(options?: object): string {
  return "/" + "api" + "/" + "check_in_terminal" + "/" + "advices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/check_in_terminal/advices/:tradelink_id(.:format) */
export function apiCheckInTerminalAdvicePath(tradelink_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "check_in_terminal" + "/" + "advices" + "/" + tradelink_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["tradelink_id","format"]);
}

/** /api/check_in_terminal/sites/:site_id(.:format) */
export function apiCheckInTerminalSitePath(site_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "check_in_terminal" + "/" + "sites" + "/" + site_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /api/check_in_terminal/available_slot(.:format) */
export function apiCheckInTerminalAvailableSlotPath(options?: object): string {
  return "/" + "api" + "/" + "check_in_terminal" + "/" + "available_slot" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/check_in_terminal/company_search(.:format) */
export function apiCheckInTerminalCompanySearchPath(options?: object): string {
  return "/" + "api" + "/" + "check_in_terminal" + "/" + "company_search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/advices/:advice_id/changes(.:format) */
export function apiAdviceChangesPath(advice_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "advices" + "/" + advice_id + "/" + "changes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["advice_id","format"]);
}

/** /api/feature_flags/:id(.:format) */
export function apiFeatureFlagPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "feature_flags" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/available_slots(.:format) */
export function apiAvailableSlotsPath(options?: object): string {
  return "/" + "api" + "/" + "available_slots" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/events_aggregated(.:format) */
export function apiEventsAggregatedIndexPath(options?: object): string {
  return "/" + "api" + "/" + "events_aggregated" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/ui_settings(.:format) */
export function apiUiSettingsPath(options?: object): string {
  return "/" + "api" + "/" + "ui_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/ui_settings/:id(.:format) */
export function apiUiSettingPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "ui_settings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/companies/acquaintances(.:format) */
export function apiCompaniesAcquaintancesPath(options?: object): string {
  return "/" + "api" + "/" + "companies" + "/" + "acquaintances" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api_docs(.:format) */
export function apiDocsPath(options?: object): string {
  return "/" + "api_docs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /companies/:company_id/settings/check_in_terminals/site/:site_id(.:format) */
export function editCompanySettingsCheckInTerminalsPath(company_id: ScalarType, site_id: ScalarType, options?: object): string {
  return "/" + "companies" + "/" + company_id + "/" + "settings" + "/" + "check_in_terminals" + "/" + "site" + "/" + site_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","site_id","format"]);
}

/** /companies/:company_id/settings/check_in_terminals/site/:site_id(.:format) */
export function updateCompanySettingsCheckInTerminalsPath(company_id: ScalarType, site_id: ScalarType, options?: object): string {
  return "/" + "companies" + "/" + company_id + "/" + "settings" + "/" + "check_in_terminals" + "/" + "site" + "/" + site_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","site_id","format"]);
}

/** /companies/:company_id/settings/check_in_terminals/site/:site_id/document/:document_locale(.:format) */
export function updateDocumentCompanySettingsCheckInTerminalsPath(company_id: ScalarType, site_id: ScalarType, document_locale: ScalarType, options?: object): string {
  return "/" + "companies" + "/" + company_id + "/" + "settings" + "/" + "check_in_terminals" + "/" + "site" + "/" + site_id + "/" + "document" + "/" + document_locale + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","site_id","document_locale","format"]);
}

/** /companies/:company_id/settings/check_in_terminals(.:format) */
export function companySettingsCheckInTerminalsPath(company_id: ScalarType, options?: object): string {
  return "/" + "companies" + "/" + company_id + "/" + "settings" + "/" + "check_in_terminals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /sites/:id/missing_status_points(.:format) */
export function missingStatusPointsSitePath(id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + id + "/" + "missing_status_points" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /sites/:id/calendar(.:format) */
export function calendarSitePath(id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + id + "/" + "calendar" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /sites/:id/calendar/advices/:advice_id(.:format) */
export function adviceCalendarSitePath(id: ScalarType, advice_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + id + "/" + "calendar" + "/" + "advices" + "/" + advice_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","advice_id","format"]);
}

/** /sites/:id/list/advices(.:format) */
export function listAdvicesSitePath(id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + id + "/" + "list" + "/" + "advices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /sites/:id/list/advices/:advice_id(.:format) */
export function listAdviceSitePath(id: ScalarType, advice_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + id + "/" + "list" + "/" + "advices" + "/" + advice_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","advice_id","format"]);
}

/** /sites/:id/detailed/advices/:advice_id(.:format) */
export function detailedAdviceSitePath(id: ScalarType, advice_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + id + "/" + "detailed" + "/" + "advices" + "/" + advice_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","advice_id","format"]);
}

/** /sites/:site_id/list/orders(.:format) */
export function siteListOrdersPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "list" + "/" + "orders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/import/orders(.:format) */
export function siteImportOrdersPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "import" + "/" + "orders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/slot_types(.:format) */
export function siteSlotTypesPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "slot_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/company_site_partner_roles/onboard(.:format) */
export function onboardSiteCompanySitePartnerRolesPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "company_site_partner_roles" + "/" + "onboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/company_site_partner_roles(.:format) */
export function siteCompanySitePartnerRolesPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "company_site_partner_roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/company_site_partner_roles/:id/edit(.:format) */
export function editSiteCompanySitePartnerRolePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "company_site_partner_roles" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/advices/:advice_id/business_processes/:id/update_time(.:format) */
export function updateTimeSiteAdviceBusinessProcessPath(site_id: ScalarType, advice_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "advices" + "/" + advice_id + "/" + "business_processes" + "/" + id + "/" + "update_time" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","advice_id","id","format"]);
}

/** /sites/:site_id/advices/:advice_id/business_processes(.:format) */
export function siteAdviceBusinessProcessesPath(site_id: ScalarType, advice_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "advices" + "/" + advice_id + "/" + "business_processes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","advice_id","format"]);
}

/** /sites/:site_id/advices/:advice_id/business_processes/:id(.:format) */
export function siteAdviceBusinessProcessPath(site_id: ScalarType, advice_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "advices" + "/" + advice_id + "/" + "business_processes" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","advice_id","id","format"]);
}

/** /sites/:site_id/advices/:id/documents(.:format) */
export function documentsSiteAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "advices" + "/" + id + "/" + "documents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/advices/:id/document(.:format) */
export function documentSiteAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "advices" + "/" + id + "/" + "document" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/advices/suggested_slot_type(.:format) */
export function suggestedSlotTypeSiteAdvicesPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "advices" + "/" + "suggested_slot_type" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/advices(.:format) */
export function siteAdvicesPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "advices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/capacity_limits(.:format) */
export function siteCapacityLimitsPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "capacity_limits" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/capacity_limits/new(.:format) */
export function newSiteCapacityLimitPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "capacity_limits" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/capacity_limits/:id/edit(.:format) */
export function editSiteCapacityLimitPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "capacity_limits" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/capacity_limits/:id(.:format) */
export function siteCapacityLimitPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "capacity_limits" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/guided_advices/:id/show_invitation_link(.:format) */
export function showInvitationLinkSiteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + "/" + "show_invitation_link" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/guided_advices/:id/send_invitation_email(.:format) */
export function sendInvitationEmailSiteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + "/" + "send_invitation_email" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/guided_advices/:id/companies/search(.:format) */
export function companiesSearchSiteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + "/" + "companies" + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/guided_advices(.:format) */
export function siteGuidedAdvicesPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/guided_advices/:id(.:format) */
export function siteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/guided_advice_settings(.:format) */
export function siteGuidedAdviceSettingsPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advice_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/guided_advice_settings/new(.:format) */
export function newSiteGuidedAdviceSettingPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advice_settings" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/guided_advice_settings/:id/edit(.:format) */
export function editSiteGuidedAdviceSettingPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advice_settings" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/guided_advice_settings/:id(.:format) */
export function siteGuidedAdviceSettingPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "guided_advice_settings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /sites/:site_id/companies/acquaintances/search(.:format) */
export function acquaintancesSearchSiteCompaniesPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "companies" + "/" + "acquaintances" + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/companies/match/batch(.:format) */
export function matchBatchSiteCompaniesPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "companies" + "/" + "match" + "/" + "batch" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:site_id/empties_site_settings/edit(.:format) */
export function editSiteEmptiesSiteSettingsPath(site_id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + site_id + "/" + "empties_site_settings" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /sites/:id/edit(.:format) */
export function editSitePath(id: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /users/:user_id/notification_setting/edit(.:format) */
export function editUserNotificationSettingPath(user_id: ScalarType, options?: object): string {
  return "/" + "users" + "/" + user_id + "/" + "notification_setting" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id","format"]);
}

/** /users/:id/edit(.:format) */
export function editUserPath(id: ScalarType, options?: object): string {
  return "/" + "users" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /partner_management/api/sites/:site_id/company_site_partner_roles/:company_site_partner_role_id/users/:id(.:format) */
export function partnerManagementApiSiteCompanySitePartnerRoleUserPath(site_id: ScalarType, company_site_partner_role_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "partner_management" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "company_site_partner_roles" + "/" + company_site_partner_role_id + "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","company_site_partner_role_id","id","format"]);
}

/** /partner_management/api/sites/:site_id/company_site_partner_roles(.:format) */
export function partnerManagementApiSiteCompanySitePartnerRolesPath(site_id: ScalarType, options?: object): string {
  return "/" + "partner_management" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "company_site_partner_roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /partner_management/api/sites/:site_id/company_site_partner_roles/:id(.:format) */
export function partnerManagementApiSiteCompanySitePartnerRolePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "partner_management" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "company_site_partner_roles" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /partner_management/api/sites/:site_id/partners/:partner_id/users(.:format) */
export function partnerManagementApiSitePartnerUsersPath(site_id: ScalarType, partner_id: ScalarType, options?: object): string {
  return "/" + "partner_management" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "partners" + "/" + partner_id + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","partner_id","format"]);
}

/** /partner_management/api/sites/:site_id/unclaimed_partner_roles/:id(.:format) */
export function partnerManagementApiSiteUnclaimedPartnerRolePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "partner_management" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "unclaimed_partner_roles" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /partner_management/api/sites/:site_id/invitation(.:format) */
export function partnerManagementApiSiteInvitationPath(site_id: ScalarType, options?: object): string {
  return "/" + "partner_management" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "invitation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /partner_management/api/partners/:partner_id/users(.:format) */
export function partnerManagementApiPartnerUsersPath(partner_id: ScalarType, options?: object): string {
  return "/" + "partner_management" + "/" + "api" + "/" + "partners" + "/" + partner_id + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["partner_id","format"]);
}

/** /partner_management/api/partner_invites(.:format) */
export function partnerManagementApiPartnerInvitesPath(options?: object): string {
  return "/" + "partner_management" + "/" + "api" + "/" + "partner_invites" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /company_user_management/api/companies/:company_id/user(.:format) */
export function companyUserManagementApiCompanyUserPath(company_id: ScalarType, options?: object): string {
  return "/" + "company_user_management" + "/" + "api" + "/" + "companies" + "/" + company_id + "/" + "user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /company_user_management/api/companies/:company_id/company_user_roles/:id/approve(.:format) */
export function approveCompanyUserManagementApiCompanyCompanyUserRolePath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "company_user_management" + "/" + "api" + "/" + "companies" + "/" + company_id + "/" + "company_user_roles" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /company_user_management/api/companies/:company_id/company_user_roles/:id/reject(.:format) */
export function rejectCompanyUserManagementApiCompanyCompanyUserRolePath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "company_user_management" + "/" + "api" + "/" + "companies" + "/" + company_id + "/" + "company_user_roles" + "/" + id + "/" + "reject" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /company_user_management/api/companies/:company_id/company_user_roles(.:format) */
export function companyUserManagementApiCompanyCompanyUserRolesPath(company_id: ScalarType, options?: object): string {
  return "/" + "company_user_management" + "/" + "api" + "/" + "companies" + "/" + company_id + "/" + "company_user_roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /company_user_management/api/companies/:company_id/company_user_roles/:id(.:format) */
export function companyUserManagementApiCompanyCompanyUserRolePath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "company_user_management" + "/" + "api" + "/" + "companies" + "/" + company_id + "/" + "company_user_roles" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /advices/:id(.:format) */
export function advicePath(id: ScalarType, options?: object): string {
  return "/" + "advices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /s/:slug/:site_id/:guided_advice_id(.:format) */
export function guidedSiteTinyPath(slug: ScalarType, site_id: ScalarType, guided_advice_id: ScalarType, options?: object): string {
  return "/" + "s" + "/" + slug + "/" + site_id + "/" + guided_advice_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["slug","site_id","guided_advice_id","format"]);
}

/** /sites/:uuid/profile(.:format) */
export function siteProfilesPath(uuid: ScalarType, options?: object): string {
  return "/" + "sites" + "/" + uuid + "/" + "profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["uuid","format"]);
}

/** /contributors/onboarding/identify(.:format) */
export function identifyUserContributorOnboardingPath(options?: object): string {
  return "/" + "contributors" + "/" + "onboarding" + "/" + "identify" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributors/onboarding/authenticate(.:format) */
export function authenticateUserContributorOnboardingPath(options?: object): string {
  return "/" + "contributors" + "/" + "onboarding" + "/" + "authenticate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /contributor/api/shipments/reference_number_conflicts(.:format) */
export function contributorApiShipmentsReferenceNumberConflictsPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "shipments" + "/" + "reference_number_conflicts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/users/me(.:format) */
export function contributorApiUsersMePath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "users" + "/" + "me" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/users/check_is_public_email(.:format) */
export function contributorApiUsersCheckIsPublicEmailPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "users" + "/" + "check_is_public_email" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/onboarding/invite_user(.:format) */
export function contributorApiOnboardingInviteUserPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "onboarding" + "/" + "invite_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/onboarding/update_invited_partner(.:format) */
export function contributorApiOnboardingUpdateInvitedPartnerPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "onboarding" + "/" + "update_invited_partner" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/onboarding/user_onboarded(.:format) */
export function contributorApiOnboardingUserOnboardedPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "onboarding" + "/" + "user_onboarded" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/invitations(.:format) */
export function contributorApiInvitationsPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "invitations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/external_booking/sites/:site_id/guided_advices/:id/create_advice(.:format) */
export function createAdviceContributorApiExternalBookingSiteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "external_booking" + "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + "/" + "create_advice" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /contributor/api/external_booking/sites/:site_id/guided_advices/:id/create_announcement(.:format) */
export function createAnnouncementContributorApiExternalBookingSiteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "external_booking" + "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + "/" + "create_announcement" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /contributor/api/external_booking/sites/:site_id/guided_advices/:id/create_special_request(.:format) */
export function createSpecialRequestContributorApiExternalBookingSiteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "external_booking" + "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + "/" + "create_special_request" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /contributor/api/external_booking/sites/:site_id/guided_advices/:id/advices/:advice_id(.:format) */
export function updateAdviceContributorApiExternalBookingSiteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, advice_id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "external_booking" + "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + "/" + "advices" + "/" + advice_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","advice_id","format"]);
}

/** /contributor/api/external_booking/sites/:site_id/guided_advices/:id(.:format) */
export function contributorApiExternalBookingSiteGuidedAdvicePath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "external_booking" + "/" + "sites" + "/" + site_id + "/" + "guided_advices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /contributor/api/advices/:id(.:format) */
export function contributorApiAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "advices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor/api/advices/:id/reserve(.:format) */
export function reserveContributorApiAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "advices" + "/" + id + "/" + "reserve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor/api/advices/:id/email_custom_request(.:format) */
export function emailCustomRequestContributorApiAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "advices" + "/" + id + "/" + "email_custom_request" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor/api/advices/:id/confirm(.:format) */
export function confirmContributorApiAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "advices" + "/" + id + "/" + "confirm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor/api/advices/:id/withdraw(.:format) */
export function withdrawContributorApiAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "advices" + "/" + id + "/" + "withdraw" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor/api/advices/:id/site(.:format) */
export function siteContributorApiAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "advices" + "/" + id + "/" + "site" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor/api/advices/:advice_id/time_deviations(.:format) */
export function contributorApiAdviceTimeDeviationsPath(advice_id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "advices" + "/" + advice_id + "/" + "time_deviations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["advice_id","format"]);
}

/** /contributor/api/advices/:advice_id/sharing_invites(.:format) */
export function contributorApiAdviceSharingInvitesPath(advice_id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "advices" + "/" + advice_id + "/" + "sharing_invites" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["advice_id","format"]);
}

/** /contributor/api/sites(.:format) */
export function contributorApiSitesPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "sites" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/slots/length(.:format) */
export function lengthContributorApiSlotsPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "slots" + "/" + "length" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/companies/without_address(.:format) */
export function withoutAddressContributorApiCompaniesPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "companies" + "/" + "without_address" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/companies/at_location(.:format) */
export function atLocationContributorApiCompaniesPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "companies" + "/" + "at_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/companies/:id(.:format) */
export function contributorApiCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor/api/company_user_role_addresses(.:format) */
export function contributorApiCompanyUserRoleAddressesPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "company_user_role_addresses" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/company_user_role_addresses/:id(.:format) */
export function contributorApiCompanyUserRoleAddressPath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "company_user_role_addresses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor/api/invitations/:invitation_id/sites/:id(.:format) */
export function contributorApiInvitationSitePath(invitation_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "invitations" + "/" + invitation_id + "/" + "sites" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["invitation_id","id","format"]);
}

/** /contributor/api/guided_advices/:guided_advice_id/sites/:id/visible_site_companies(.:format) */
export function visibleSiteCompaniesContributorApiGuidedAdviceSitePath(guided_advice_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "guided_advices" + "/" + guided_advice_id + "/" + "sites" + "/" + id + "/" + "visible_site_companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["guided_advice_id","id","format"]);
}

/** /contributor/api/guided_advices/:guided_advice_id/sites/:id(.:format) */
export function contributorApiGuidedAdviceSitePath(guided_advice_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "guided_advices" + "/" + guided_advice_id + "/" + "sites" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["guided_advice_id","id","format"]);
}

/** /contributor/api/guided_advices/invitations(.:format) */
export function invitationsContributorApiGuidedAdvicesPath(options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "guided_advices" + "/" + "invitations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor/api/guided_advices/:id(.:format) */
export function contributorApiGuidedAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor" + "/" + "api" + "/" + "guided_advices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /multi_stop_bookings/new(.:format) */
export function newContributorMultiStopBookingPath(options?: object): string {
  return "/" + "multi_stop_bookings" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor_advices/:id/list(.:format) */
export function listContributorAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor_advices" + "/" + id + "/" + "list" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor_advices/:id/reserved(.:format) */
export function reservedContributorAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor_advices" + "/" + id + "/" + "reserved" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor_advices/:id/book(.:format) */
export function bookContributorAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor_advices" + "/" + id + "/" + "book" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor_advices/:id/partner_responsible_for_documents_callback(.:format) */
export function partnerResponsibleForDocumentsCallbackContributorAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor_advices" + "/" + id + "/" + "partner_responsible_for_documents_callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /contributor_advices(.:format) */
export function contributorAdvicesPath(options?: object): string {
  return "/" + "contributor_advices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /contributor_advices/:id(.:format) */
export function contributorAdvicePath(id: ScalarType, options?: object): string {
  return "/" + "contributor_advices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /privacy_policy(.:format) */
export function pagesPrivacyPolicyPath(options?: object): string {
  return "/" + "privacy_policy" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /legal_notice(.:format) */
export function pagesLegalNoticePath(options?: object): string {
  return "/" + "legal_notice" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /operator/api/sites/:site_id/capacity/utilization(.:format) */
export function utilizationOperatorApiCapacitySitePath(site_id: ScalarType, options?: object): string {
  return "/" + "operator" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "capacity" + "/" + "utilization" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /operator/api/sites/:site_id/capacity/limits/:id/utilization(.:format) */
export function utilizationOperatorApiCapacityLimitPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "operator" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "capacity" + "/" + "limits" + "/" + id + "/" + "utilization" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /operator/api/sites/:site_id/shipments/:shipment_id/documents/batch(.:format) */
export function batchOperatorApiShipmentDocumentsPath(site_id: ScalarType, shipment_id: ScalarType, options?: object): string {
  return "/" + "operator" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "shipments" + "/" + shipment_id + "/" + "documents" + "/" + "batch" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","shipment_id","format"]);
}

/** /operator/api/sites/:site_id/shipments/:shipment_id/documents(.:format) */
export function operatorApiShipmentDocumentsPath(site_id: ScalarType, shipment_id: ScalarType, options?: object): string {
  return "/" + "operator" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "shipments" + "/" + shipment_id + "/" + "documents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","shipment_id","format"]);
}

/** /operator/api/sites/:site_id/shipments/:shipment_id/documents/:id(.:format) */
export function operatorApiShipmentDocumentPath(site_id: ScalarType, shipment_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "operator" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "shipments" + "/" + shipment_id + "/" + "documents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","shipment_id","id","format"]);
}

/** /operator/api/sites/:site_id/orders/batch(.:format) */
export function batchOperatorApiOrdersPath(site_id: ScalarType, options?: object): string {
  return "/" + "operator" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "orders" + "/" + "batch" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /operator/api/sites/:site_id/orders/:id/status_transitions(.:format) */
export function statusTransitionsOperatorApiOrderPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "operator" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "orders" + "/" + id + "/" + "status_transitions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /operator/api/sites/:site_id/orders/:id(.:format) */
export function operatorApiOrderPath(site_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "operator" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "orders" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","id","format"]);
}

/** /custom_fields/api/custom_field_types/:resource_type(.:format) */
export function customFieldsApiPath(resource_type: ScalarType, options?: object): string {
  return "/" + "custom_fields" + "/" + "api" + "/" + "custom_field_types" + "/" + resource_type + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["resource_type","format"]);
}

/** /empties(.:format) */
export function emptiesPath(options?: object): string {
  return "/" + "empties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/transactions(.:format) */
export function emptiesTransactionsPath(options?: object): string {
  return "/" + "empties" + "/" + "transactions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/balances(.:format) */
export function emptiesBalancesPath(options?: object): string {
  return "/" + "empties" + "/" + "balances" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/inventory(.:format) */
export function emptiesInventoryPath(options?: object): string {
  return "/" + "empties" + "/" + "inventory" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/transaction_confirmation_documents/:user_sgid/:transaction_sgid(.:format) */
export function showEmptiesTransactionConfirmationDocumentsPath(user_sgid: ScalarType, transaction_sgid: ScalarType, options?: object): string {
  return "/" + "empties" + "/" + "transaction_confirmation_documents" + "/" + user_sgid + "/" + transaction_sgid + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_sgid","transaction_sgid","format"]);
}

/** /empties/api/transactions/exposed(.:format) */
export function exposedEmptiesApiTransactionsPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "transactions" + "/" + "exposed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/api/transactions/:id/changelog(.:format) */
export function changelogEmptiesApiTransactionPath(id: ScalarType, options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "transactions" + "/" + id + "/" + "changelog" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /empties/api/transactions(.:format) */
export function emptiesApiTransactionsPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "transactions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/api/transactions/:id(.:format) */
export function emptiesApiTransactionPath(id: ScalarType, options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "transactions" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /empties/api/transaction_confirmation_documents/:id(.:format) */
export function emptiesApiTransactionConfirmationDocumentPath(id: ScalarType, options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "transaction_confirmation_documents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /empties/api/balances(.:format) */
export function emptiesApiBalancesPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "balances" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/api/balances/messages(.:format) */
export function emptiesApiBalancesMessagesPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "balances" + "/" + "messages" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/api/inventories(.:format) */
export function emptiesApiInventoriesPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "inventories" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/api/ownerships(.:format) */
export function emptiesApiOwnershipsPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "ownerships" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/api/sites/:site_id/settings(.:format) */
export function emptiesApiSiteSettingsPath(site_id: ScalarType, options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "sites" + "/" + site_id + "/" + "settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["site_id","format"]);
}

/** /empties/api/sites(.:format) */
export function emptiesApiSitesPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "sites" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/api/partners/internal(.:format) */
export function internalEmptiesApiPartnersPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "partners" + "/" + "internal" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /empties/api/partners/external(.:format) */
export function externalEmptiesApiPartnersPath(options?: object): string {
  return "/" + "empties" + "/" + "api" + "/" + "partners" + "/" + "external" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /advice_changelog/api/advices/:advice_id/changelog(.:format) */
export function adviceChangelogApiAdviceChangelogPath(advice_id: ScalarType, options?: object): string {
  return "/" + "advice_changelog" + "/" + "api" + "/" + "advices" + "/" + advice_id + "/" + "changelog" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["advice_id","format"]);
}

/** /advice_search/api/operator(.:format) */
export function adviceSearchApiOperatorPath(options?: object): string {
  return "/" + "advice_search" + "/" + "api" + "/" + "operator" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /advice_search/api/contributor(.:format) */
export function adviceSearchApiContributorPath(options?: object): string {
  return "/" + "advice_search" + "/" + "api" + "/" + "contributor" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /insights/api(.:format) */
export function insightsApiPath(options?: object): string {
  return "/" + "insights" + "/" + "api" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /insights/api/admin_insights(.:format) */
export function insightsApiAdminInsightsPath(options?: object): string {
  return "/" + "insights" + "/" + "api" + "/" + "admin_insights" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /insights/api/advices(.:format) */
export function insightsApiAdvicesPath(options?: object): string {
  return "/" + "insights" + "/" + "api" + "/" + "advices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}
